<div class="column p-20 row-gap-30">
  <div class="d-flex justify-space-between align-center">
    @if (data.title) {
    <h2 class="font-size-20 font-semi-bold">{{ data.title }}</h2>
    }
    <mat-dialog-actions>
      <button class="btn-icon p-5" mat-dialog-close>
        <img src="/assets/icons/close-dialog.svg" alt="fechar" />
      </button>
    </mat-dialog-actions>
  </div>

  <div class="column row-gap-15 align-center">
    @if (data.subtitle) {
    <span class="text-center font-semi-bold">{{ data.subtitle }}</span>
    } @if (data.message) {
    <span class="text-center line-height-22 font-regular">{{ data.message }}</span>
    }
  </div>

  <div class="w-full pt-10 d-flex justify-center column-gap-30">
    @if (data.cancelText) {
    <button type="button" class="btn-secondary px-15 btn-action" (click)="closeDialog()">
      {{ data.cancelText }}
    </button>
    } @if (data.confirmText) {
    <button type="button" class="btn-primary px-20 btn-action" (click)="onSubmit()">
      {{ data.confirmText }}
    </button>
    }
  </div>
</div>
