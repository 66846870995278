import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef
} from '@angular/material/dialog';
import { ICustomDialogConfig } from '../../../core/interfaces/generic.interface';

@Component({
  selector: 'app-custom-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogActions, MatDialogClose],
  templateUrl: './custom-dialog.component.html',
  styleUrl: './custom-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDialogComponent {
  data!: ICustomDialogConfig;

  constructor(
    private dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ICustomDialogConfig
  ) {
    this.data = data;
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }
}
